const initialState = {
  remainingFeatureBalance: null
};
 
export const ASReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REMAINING_FEATURE_BALANCE':
        return {
            ...state,
            remainingFeatureBalance: action.payload
        }
    case 'IS_EXPANDED_VIEW':
      return {
          ...state,
          isExpandedView: action.payload
      }
    default:
        return state;
  }
}