import { Box, Button, InputLabel, MenuItem, Modal, Typography } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import moment from "moment";
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import '../plugin-parameters/plugin-parameters';
import { isNullOrEmpty } from "../../../services/data-source-validation";

export const Parameters = (props) => {

    const {collection, index, dateObject, complexTypeValue, dynamicList, handleOnInputChange} = props;

    return (
        <div className="filter-box ">
            {collection.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput || collection.parameterTypeCD === AppEnum.ParameterTypeValue.Fixed ?
                < ASTextField
                    key={index}
                    className="text-field1 label-text-field "
                    type={collection?.sysDataTypeId ===  AppEnum.DataTypeId.Date ? "date" : collection?.sysDataTypeId === AppEnum.DataTypeId.Number ? "number" : "text"}
                    fullWidth
                    label={collection?.parameterName}
                    variant="outlined"
                    disabled={collection?.parameterTypeCD === "Fixed" ? true : false}
                    onChange={(e) => handleOnInputChange(e, index, false)}

                    defaultValue={collection?.sysDataTypeId === AppEnum.DataTypeId.Date ? moment(dateObject).format(AppEnum.DateFormats.Y_M_D) : isNullOrEmpty(collection?.parameterValue) ? collection.defaultValue : collection?.parameterValue
                    }
                /> : null
            }

            {
                collection.parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable ?
                    collection?.sysDataTypeId ===  AppEnum.DataTypeId.List ?
                        < ASTextField
                            size="small"
                            className="text-field1 label-text-field "
                            type={"text"}
                            sx={{ fontSize: "12px" }}
                            fullWidth
                            defaultValue={complexTypeValue?.find((i) => i.Default)?.Label ?? ""}
                            label={collection?.parameterName}
                            variant="outlined"
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                },
                            }}
                            onChange={(e) => handleOnInputChange(e, index, true, false)}
                        >
                            {complexTypeValue?.map((list, index) => {
                                return (
                                    <MenuItem sx={{ fontSize: "12px" }} value={list?.Label} key={`dynamic ${index}`} >
                                        {list.Label}
                                    </MenuItem>
                                );
                            })}
                        </ASTextField>
                        :
                        <ASTextField
                            className="text-field1 label-text-field"
                            size="small"
                            type={"text"}
                            sx={{ fontSize: "12px" }}
                            fullWidth
                            label={collection?.parameterName}
                            variant="outlined"
                            defaultValue={complexTypeValue?.DefaultValue}
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                },
                            }}
                            onChange={(e) => handleOnInputChange(e, index, true, true)}
                        >
                            {dynamicList?.map((list, index) => {
                                return (
                                    <MenuItem value={list.value} key={`dynamic ${index}`} >
                                        {list.label}
                                    </MenuItem>
                                );
                            })}
                        </ASTextField> : null
            }
        </div >
    )
}