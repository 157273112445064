/** @format */

import React, { useState, useEffect, cloneElement } from "react";
import { Box, Modal, IconButton } from "@mui/material";
import {
  OpenWith as OpenWithIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import "./expandableView.scss";
import { useDispatch } from "react-redux";
import { setIsExpandedView } from "../../../action";
// import expandIcon from '/public/assets/expandIcon.png';


const ExpandableView = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const handleExpand = () => {
    setIsExpanded(true);
    dispatch(setIsExpandedView(true));
  };

  const handleClose = () => {
    setIsExpanded(false);
    dispatch(setIsExpandedView(false));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    if (isExpanded) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isExpanded]);

  return (
    <Box position="relative">
      {children}
      <div className="expandable-icon">
        <IconButton onClick={handleExpand} sx={{paddingRight:"2px"}}>
        <img src="../../assets/expandIcon.png" alt="Expand" style={{ width: '16px', height: '16px' }} />
        </IconButton>
      </div>
      <Modal
        open={isExpanded}
        onClose={handleClose}
        aria-labelledby="expandable-component-title"
        aria-describedby="expandable-component-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            id="expandable-component-description"
            sx={{
              overflow: "auto",
              height: "98%",
              // paddingBottom: "40px" // Add some padding to ensure content doesn't overlap the button
            }}
          >
             {children}
          </Box>
          <IconButton
            onClick={handleClose}
            sx={{ position: "fixed", bottom: "5px", right: "15px" }}
          >
           <img src="../../assets/collapseIcon.png" alt="collapse" style={{ width: '20px', height: '20px' }} />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default ExpandableView;
