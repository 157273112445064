import {CardContent, Typography} from "@mui/material";
import React, { Fragment, useMemo ,useState } from "react";
import { ASButton } from "../../shared/as-button/as-button";
import { Link } from "react-router-dom";
import { ASTextField } from "../../shared/as-text-fields";
import './data-collections.scss'
import { SearchIconSvg } from "../../constants/common-svgs";
import { InputAdornment, Paper } from "@mui/material";
import { ASDataCollections } from "../../shared/menu-icons/as-data-collections";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import styles from "../../styles/_exports.module.scss";
import TableHead from '@mui/material/TableHead';
import moment from "moment";
import { AppEnum } from "../../constants/app-enum";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import MessageToaster from "../../shared/message-toaster/message-toaster";
import { FormatListBulleted, Usb, WorkspacesOutlined } from "@mui/icons-material";
import { selectedMenuItemBackgroundColor } from "./collection-parameters/collection-parameters";
import { ASConnection } from "../../shared/menu-icons/as-connection";
import { ASGroups } from "../../shared/menu-icons/as-groups";
import "./../../App.scss";
import CopyCollection from "./copy-collection/copy-collection";
import UpgradeButtonView from "../common/upgradeButton/UpgradeButtonView";
import { useSelector } from "react-redux";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DefaultView from "../common/defaultView/DefaultView";
import { ALL } from "../../controllers/data-collections/data-collections-controller";
import { useASContext } from "../../controllers/context-api/as-context";

const CollectionRowTooltp = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: styles.bodyBackground,
        color: styles.secondaryColor,
        fontSize: theme.typography.pxToRem(14),
        border: `1px solid ${styles.text_2}`,
    },
}));


const DataCollections = (props) => {
    const remainingFeatureBalance = useSelector((state) => state.remainingFeatureBalance);

    const { uniqueFilteredDataCollections, filteredDataCollections, isShrink, dataCollectionsHeader, filterHeader, filterMenuName, collectionAsDataSource, filteredDataCollectionsAsSource,
        selectedFilterId, selectedCollectionIndex, isCollectionMenuOpen, collectionEnchorEl, searchedCollectionList, errorMessage, collectionAsSourceOptions
        , dataCollections } = props.state;

    const asContext = useASContext();


    const filterIcon = useMemo(() => {
        return selectedFilterId === AppEnum.CollectionFilterId.category ?
            <FormatListBulleted className="filter-icons large-size" /> :

            selectedFilterId === AppEnum.CollectionFilterId.connection ?
                <Usb className="filter-icons rotate-90 large-size" /> :

                <ASGroups size={'2em'} className={'temp'} />

    }, [selectedFilterId])

    const isActiveConnection = useMemo(() => {
        if (filterMenuName) {
            const conn = dataCollections.find(collection => collection?.connection?.name === filterMenuName);
            return conn?.connection?.isActive ?? true;
        }
        return true;
    }, [dataCollections, filterMenuName, filteredDataCollections]);

    return (
        <Fragment>
            <div className="data-collections-container">
                <div className="collections-header-div">

                    <div className="data-name-div">
                        <div>
                            <span>Total <span>{filteredDataCollections?.length}</span></span>
                            <label>Data Collections</label>

                            <CopyCollection open={props.isDialogOpen} onClose={props.handleCloseDialog} collectionUId={props.collectionUId} setDataCollectionListRefresh={props.setDataCollectionListRefresh}/>
                        </div>
                    </div>

                    <div className="search-btn-div">

                        {remainingFeatureBalance === null ? (
                            ""
                        ) : (
                            remainingFeatureBalance <= 0 ? (
                                <UpgradeButtonView balanceFeature="COLLECTIONS" />
                            ) : (
                                <div className="add-new-btn-data">

                                    <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                                        onClick={() => { }}
                                        rounded={true}
                                        primary={true}
                                    >
                                        <Link className="add-new-link" to="/data-collections/add-new-data-collection">
                                            <i className="plus-svg">+</i> ADD NEW
                                        </Link>
                                    </ASButton>

                                </div>
                            )
                        )}

                    </div>

                </div>

                {
                    !(asContext.subscriptionValidation.isSubscriptionExist) ? (<DefaultView
                        title="You have no subscription added"
                        firstPart="Click on "
                        buttonLink={"/checkout"}
                        buttonText="checkout"
                        secondPart= " to subscribe." 
                    />) : (
                        remainingFeatureBalance == null ? ("") : (props.responseRecieved && dataCollections != null && dataCollections.length == 0 ? (
                            <div>
                                <DefaultView
                                    title="You have not created any collections yet."
                                    firstPart="Click on "
                                    buttonLink={"/data-collections/add-new-data-collection"}
                                    buttonText="Add"
                                    secondPart= " to add your first collection."
                                    />
                            </div>
                        ) : (props.responseRecieved && dataCollections != null && dataCollections?.length > 0 ? (
                            <div className="collection-paper-group">
                                <div className="connection-search-div">
                                    <div className="collection-filters">
                                        {
                                            filterHeader.map((filter, index) => {
                                                const newList = props.connectionMenuItems(filter)
                                                return (
                                                    <ASTextField
                                                        key={index}
                                                        className="role-text-field connextions-dropdown"
                                                        label={`Filter by ${filter.filterName}`}
                                                        variant="outlined"
                                                        SelectProps={{sx: { height: "60px", padding: "0px" }}}
                                                        onClick={() => props.filterDataCollectionsByTab(filter)}
                                                        onChange={(event) => props.onSelectedConnectionChange(event.target) }
                                                        select={true}
                                                        value={props.selectedConnectionValue[index]}
                                                        name={filter.filterName}
                                                    >
                                                        {
                                                            newList.map((data, index) => {
                                                                const filterName = (
                                                                    data?.name == ALL ? data?.name 
                                                                        : filter.filterId === AppEnum.CollectionFilterId.connection ? data?.connection?.name 
                                                                            : filter.filterId === AppEnum.CollectionFilterId.category ? data?.category?.categoryName : data?.groupName
                                                                    )
        
                                                                return <MenuItem
                                                                    key={index}
                                                                    value={filterName}
                                                                    name={filterName}
                                                                    selected={data.isSelected}
                                                                    sx={{ height: "45px" }}
                                                                    onClick={() =>
                                                                        props.filterDataCollectionsByMenu(data, filterName, null, filter.filterId)
                                                                    }
                                                                >
                                                                    {props.menuItemsForConnections(data, filterName)}
                                                                </MenuItem>
        
                                                            })}
                                                    </ASTextField>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="search-input">
                                        <ASTextField
                                            className={isShrink ? 'label-text-field' : "collections-text-field"}
                                            fullWidth
                                            label="Search"
                                            key="Search"
                                            variant="outlined"
                                            onFocus={props.onFocusSearchBoxHandler}
                                            onBlur={props.onBlurSearchBoxHandler}
                                            InputLabelProps={{ shrink: isShrink }}
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    props.onChangeSearchCollectionHandler(event.target.value);
                                                }, 100)
                                            }}
                                            inputRef={props.searchInputRef}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <CloseIcon className="clear-icon" onClick={() => { props.onChangeSearchCollectionHandler(null) }} />
                                                </InputAdornment>,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <span className='search-span-svg'>
                                                            {SearchIconSvg}</span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="collection-paper-group-">
                                    <Paper className="collection-paper">
        
                                        <div className="flex-div-between coll-sec-header">
                                            <div className="collection-header">
                                                {filterIcon}
        
                                                <div style={{ display: "grid" }}>
                                                    {(isActiveConnection) ? <label>{filterMenuName ? filterMenuName : filteredDataCollections[0]?.groupName} </label> :
                                                        <label>{filterMenuName ? filterMenuName : filteredDataCollections[0]?.groupName} <span>(Inactive)</span> </label>}
                                                    <span>Data Collections</span>
                                                </div>
                                            </div>
        
                                            <div className="flex-div-between col-dropdown">
                                                <ASConnection size={'2em'} className={'data-coll-icon'} />
        
                                                <ASTextField
                                                    className="role-text-field"
                                                    label="Collection as data source"
                                                    key="Collection as data source"
                                                    variant="outlined"
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: 'basic-info-select', sx: selectedMenuItemBackgroundColor
                                                        }
                                                    }}
                                                    select={true}
                                                    onChange={(event) =>
                                                        props.onSelectCollectionAsSourceHandler(event.target.value)
                                                    }
                                                    value={collectionAsDataSource}
                                                >
                                                    {collectionAsSourceOptions.map((menu) => (
                                                        <MenuItem
                                                            key={menu.title}
                                                            value={menu.title}
                                                            name={menu.title}
                                                            selected={menu.isSelected}
                                                        >
                                                            {menu?.title}
                                                        </MenuItem>
                                                    ))}
                                                </ASTextField>
                                            </div>
        
                                        </div>
        
                                        {
                                            errorMessage &&
                                            <div className="collection-error">
                                                <MessageToaster errorMessage={errorMessage} showLessGap={true} />
                                            </div>
                                        }
                                        <div>
                                            <TableContainer className ="tableHeight">
        
                                        <Table
                                            aria-labelledby="tableTitle"
                                            size='medium'
                                            className="collection-table"
                                        >
                                            <TableHead>
        
                                                <TableRow>
                                                    {
                                                        dataCollectionsHeader.map((dataHead, index) => {
        
                                                            return (
                                                                <TableCell
                                                                    key={`data-coll-header ${index}`}
                                                                    align='left'
                                                                    sx={{ color: styles.secondaryColor, fontWeight: styles.semiBold }}
                                                                    scope="row"
                                                                >
                                                                    {dataHead.headerTitle}
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
        
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (props.searchInputRef?.current?.value ? searchedCollectionList
                                                        : (filteredDataCollectionsAsSource))?.map((data, index) => {
        
                                                            return (
                                                                <CollectionRowTooltp title={(!data?.connection?.isActive && "Connection for this Collection is Inactive")} placement="top" slotProps={{
                                                                    popper: {
                                                                        modifiers: [
                                                                            {
                                                                                name: 'offset',
                                                                                options: {
                                                                                    offset: [0, -30],
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}>
                                                                    <TableRow
                                                                        hover
                                                                        onClick={() => !data?.connection?.isActive ? null : props.editDataCollection(null, data)}
                                                                        key={`filtered-coll ${index}`}
                                                                        sx={{ backgroundColor: (!data.isActive || !data?.connection?.isActive) ? "#F2F2F2" : "" }}
                                                                        className='collection-row'
                                                                    >
                                                                        <TableCell
                                                                            align="left"
                                                                            sx={{ color: styles.mutedColor }}
                                                                        >
                                                                            {data?.name}
                                                                        </TableCell>
        
                                                                        <TableCell
                                                                            align="left"
                                                                            sx={{ color: styles.mutedColor, }}
                                                                        >
                                                                            <div className="flex-div-cell">
                                                                                <img alt="type-icon" src={`./assets/${data?.connection?.connectionImageIcon}`} />
                                                                                {data?.connection?.name}
                                                                            </div>
                                                                        </TableCell>
        
        
                                                                        <TableCell align="left"
                                                                            sx={{ color: styles.mutedColor }}
                                                                        >
                                                                            {
                                                                                data?.lastAccessedDateTime ?
                                                                                    moment.utc(data?.lastAccessedDateTime).local().format('DD/MM/YYYY hh:mm A')
                                                                                    : moment.utc(data?.modifiedDateTime).local().format('DD/MM/YYYY hh:mm A')
                                                                            }
                                                                        </TableCell>
        
        
                                                                        <TableCell align="left"
                                                                            sx={{ color: styles.mutedColor }}
                                                                        >
                                                                            {
                                                                                data?.isAccessableToAllUser
                                                                                    ? 'All users'
                                                                                    : data?.userCount === 0
                                                                                        ? 'No users'
                                                                                        : data?.userCount === 1
                                                                                            ? '1 user'
                                                                                            : `${data?.userCount} users`
                                                                            }
                                                                        </TableCell>
        
                                                                                <TableCell align="left" sx={{ color: styles.mutedColor }}>
                                                                                    <MoreVertIcon
                                                                                        id={`${String(index)}+vertIcon`}
                                                                                        aria-controls={isCollectionMenuOpen ? `${String(index)}+collection` : undefined}
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded={isCollectionMenuOpen ? 'true' : undefined}
                                                                                        onClick={(event) => props.openCollectionMenuOption(event, true, index)}
                                                                                        className='edit-user-icon'
                                                                                    >
                                                                                        Dashboard
                                                                                    </MoreVertIcon>
        
                                                                                    <Menu
                                                                                id={`${String(index)}+menu`}
                                                                                aria-labelledby={index}
                                                                                anchorEl={selectedCollectionIndex === index ? collectionEnchorEl : null}
                                                                                open={selectedCollectionIndex === index ? isCollectionMenuOpen : false}
                                                                                onClose={(event) => props.closeCollectionMenuOption(event)}
                                                                                anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                                                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                                                className='edit-user-menu'
                                                                            >
                                                                                {(((!data?.isTemplateCollection || !data?.isInheritCollection) && data?.connection?.isActive)) ? <MenuItem onClick={(event) => props.editDataCollection(event, data)}>Edit Collection</MenuItem> : ''}
                                                                                {(!data?.isTemplateCollection || !data?.isInheritCollection) ? <MenuItem onClick={(event) => props.deleteDataCollection(event, data)}>{"Delete Collection"}</MenuItem> : ''}
                                                                                <MenuItem
                                                                                    onClick={(event) => props.routeToUserPermission(event, data)}
                                                                                >Users permission</MenuItem>
                                                                                <MenuItem onClick={(event) => props.routeToCopyCollection(event, data)}>Copy Collection</MenuItem>
                                                                                {
                                                                                    data?.connection?.isActive ? <MenuItem
                                                                                                                        onClick={(event) => {
                                                                                                                        event.stopPropagation();
                                                                                                                        props.openDataVisualizer(event, data);
                                                                                                                    }}
                                                                                                                    >
                                                                                                                    Data Visualizer
                                                                                                                </MenuItem>
                                                                                                                :
                                                                                                                ''
                                                                                }
                                                                                
                                                                            </Menu>
                                                                        </TableCell>
        
                                                                    </TableRow>
                                                                </CollectionRowTooltp> 
                                                                    );
                                                                })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Paper>
                                </div>
                            </div>
                        ) : (<div></div>)))
                    )
                }

            </div>
        </Fragment>
    )
}

export default DataCollections;