
import { FormControlLabel,MenuItem, Paper } from "@mui/material";
import './collection-type-and-object.scss'
import { useState,useRef } from "react";
import { ASTextField } from "../../../shared/as-text-fields";
import { selectedMenuItemBackgroundColor } from "../collection-parameters/collection-parameters";
import { useParams } from "react-router-dom";
import { ASRadioGroup } from "../../../shared/as-radio-group";
import CodeMirror from "@uiw/react-codemirror";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { vscodeKeymap } from "@replit/codemirror-vscode-keymap";
import { EditorView, keymap } from "@codemirror/view";
import ExpandableView from "../../common/expandableView/expandableView";
import Checkbox from '@mui/material/Checkbox';
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import { InputConstants } from "../../../constants/input-constant";
import { AppEnum } from "../../../constants/app-enum";
import { useSelector } from "react-redux";

const CollectionTypeAndObject = (props) => {

  const { collectionUId: collectionUId } = useParams();
  const { collectionTypeList, sysCollectionTypeId, sourceName, uiLabel, sourceNamePart4, sysCollectionSubTypeId } = props.state;
  const { sourceNameLabel, isRest , setState, handleOnChangeSqlEditor, isCloudConnection, isTemplateCompany } = props;
  const isExpandedView = useSelector((state) => state.isExpandedView);

	const lightTheme = createTheme();
  const darkTheme = createTheme({ palette: { type: "dark" } });
  const KEYWORDS = [
    "select",
    "from",
    "where",
    "and",
    "or",
    "not",
    "in",
    "between",
    "contains",
    "array",
    "limit",
    "offset",
    "union",
    "intersect",
    "except",
    "order by",
    "asc",
    "desc",
    "like",
    "join"
  ];

  const TENSORS = ["images", "labels"];

  const dialect = SQLDialect.define({
    keywords: KEYWORDS.join(" "),
    builtin: TENSORS.join(" ")
  });

  const [theme, setTheme] = useState(lightTheme);
  const codeTheme = EditorView.theme({
    "&": {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border:"1px solid rgba(189,189,189,1)",
      // width:"750px",
      height: isExpandedView ? 'calc(100vh - 192px)': "180px",
      maxHeight: isExpandedView ? '' : "180px",
      overflowY:"auto",
      borderRadius:"4px",
      outline:"none !important"
    },
    ".cm-line": {
      background: "none"
    },
    ".cm-line .ͼb": {
      color: theme.palette.primary.light
    },
  });

  const codeMirrorRef = useRef(null);

  return (
    
    <Paper
      sx={{
        minHeight: "100px",
        width: "750px",
        padding: "20px",
        paddingBottom: "0px"
      }}
      ref={props.prevStepRef}
    >
      <ExpandableView>
      <div className="collection-type-container">
        {(collectionTypeList?.length > 0 && isRest) ? (
          <div>
            {
              isTemplateCompany && (
                <div>
                  <ASTextField
                    className="text-field"
                    type={InputConstants.text}
                    fullWidth
                    label="Select a Collection Sub Type"
                    variant="outlined"
                    value={sysCollectionSubTypeId}
                    onChange={(event) => props?.onChangeCollectionSubTypeHandler(event?.target?.value)}
                    select={true}
                    SelectProps={{
                      MenuProps: {
                        className: "menu-role-div",
                        sx: selectedMenuItemBackgroundColor,
                      },
                    }}
                  >
                    {collectionTypeList.filter(li => li.id !== AppEnum.SysCollectionTypeId.REST).map((list, index) => {
                      return (
                        <MenuItem value={list?.id} key={`collectionsubType ${index}`} >
                          {list.typeName}
                        </MenuItem>
                      );
                    })}
                  </ASTextField>
                </div>
              )
            }
             <div>
              REST Data Url 
            </div>
          </div>         
        ) : (
          <ASTextField
            className="role-text-field"
            label="Select a Collection Type"
            key="Select a Collection Type"
            variant="outlined"
            SelectProps={{
              MenuProps: {
                className: "basic-info-select",
                sx: selectedMenuItemBackgroundColor,
              },
            }}
            select={true}
            onChange={(event) =>
              props.onSelectCollectionTypeHandler(event.target.value)
            }
            value={sysCollectionTypeId}
            disabled={collectionUId ? true : false}
            style={{width:"50%"}}
          >
            {collectionTypeList?.map((collection) => (
              <MenuItem
                key={collection.id}
                value={collection.id}
                name={collection?.typeName}
              >
                {collection?.typeDesc}
              </MenuItem>
            ))}
          </ASTextField>
        )}

        {isRest && !isCloudConnection ? (
          <div className="collection_label_div">
            <label className="collection_label">{sourceNameLabel}</label>
            <div className="collection_label_div_sec">
              <ASTextField
                className="text-field"
                label={uiLabel}
                variant="outlined"
                onChange={props.onChangeCollectionObjectHandler}
                value={sourceName}
              />
            </div>
          </div>
        ) : !isCloudConnection ? (sysCollectionTypeId === 3 ? (
          <ThemeProvider theme={theme}>
            <CodeMirror
              ref={codeMirrorRef}
              indentWithTab={false}
              extensions={[sql({ dialect }), keymap.of(vscodeKeymap)]}
              onChange={handleOnChangeSqlEditor} // Call the handleChange function on editor change
              theme={codeTheme}
              value={sourceName}
            />
          </ThemeProvider>
          ) : (
          <ASTextField
            className="text-field"
            label={uiLabel}
            variant="outlined"
            onChange={props.onChangeCollectionObjectHandler}
            value={sourceName}
          />
        )) : ""}

        {(isRest  && !isCloudConnection) && (
          <div>
            <ASRadioGroup
              radioInputs={props.radioButtons}
              onChange={(_e) => props.onChangeRestRequestHandler(_e)}
            />
            <ASTextField
              className="text-field"
              label="Data Json Path"
              variant="outlined"
              onChange={props.onChangeCollectionDataPathHandler}
              value={sourceNamePart4}
            />
            <FormControlLabel control={
                                <Checkbox checked={props?.state?.returnRawData}
                                onChange={(event) => props.onToggleDataFormat(event)}
                                sx={muiCheckboxStyle}
                                />
                                }
                                label = "Return Raw Data"
            />
          </div>
        )}
      </div>
    </ExpandableView>
    </Paper>
  );
}

export default CollectionTypeAndObject;
