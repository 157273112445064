import React, { Fragment, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Paper } from "@mui/material";
import { Link } from "react-router-dom";
import "./users-list.scss";
import styles from "../../styles/_exports.module.scss";
import { ASButton } from "../../shared/as-button/as-button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AppEnum } from "../../constants/app-enum";
import MuiDialog from "../../shared/mui-dialog/mui-dialog";
import "./../../App.scss";
import UpgradeButtonView from "../common/upgradeButton/UpgradeButtonView";
import { useSelector } from "react-redux";

const renderAccessText = (user) => {
  if (
    (user.totalCollections === user.totalCollectionAccess &&
      user.totalConnections === user.totalConnectionAccess) &&
    (user.userRole === AppEnum.UserRoleIds.CompanyAdmin)
  ) {
    return "Everything";
  } else if (
    user.totalCollections === user.totalCollectionAccess &&
    user.totalConnections !== user.totalConnectionAccess
  ) {
    return user.totalConnectionAccess === 0
      ? `All Collection`
      : `All Collection and ${user.totalConnectionAccess} Connection`;
  } else if (
    user.totalCollections !== user.totalCollectionAccess &&
    user.totalConnections === user.totalConnectionAccess
  ) {
    return user.totalCollectionAccess === 0
      ? `All Connection`
      : `All Connection and ${user.totalCollectionAccess} Collection`;
  } else if (
    user.totalCollections !== user.totalCollectionAccess &&
    user.totalConnections !== user.totalConnectionAccess
  ) {
    return user.totalCollectionAccess === 0 && user.totalConnectionAccess === 0
      ? `No Access`
      : user.totalCollectionAccess !== 0 && user.totalConnectionAccess === 0
        ? `${user.totalCollectionAccess} Collection (s)`
        : user.totalCollectionAccess === 0 && user.totalConnectionAccess !== 0
          ? `${user.totalConnectionAccess} Connection (s)`
          : `${user.totalCollectionAccess} Collection and ${user.totalConnectionAccess} Connection`;
  } else if (
    user.totalCollections === user.totalCollectionAccess &&
    user.totalConnections === 0 &&
    user.totalConnectionAccess === 0
  ) {
    return user.totalCollections === 0 ? `Nothing` : `All Collections`;
  } else if (
    user.totalConnections === user.totalConnectionAccess &&
    user.totalCollections === 0 &&
    user.totalCollectionAccess === 0
  ) {
    return user.totalConnections === 0 ? `Nothing` : `All Collections`;
  } else {
    return "";
  }
};

const getAvatarStyle = (user) => {
  const userRole = user.userRole;
  if (userRole === AppEnum.UserRoleIds.ReportUser) {
    return styles.avatarBackGreen;
  } else if (userRole === AppEnum.UserRoleIds.CompanyAdmin) {
    return styles.primaryColor;
  } else if (userRole === AppEnum.UserRoleIds.SuperAdmin) {
    return styles.avatarBackground;
  } else {
    return styles.avatarBackBlue;
  }
};

const getAvatarClassName = (user) => {
  const userRole = user.userRole;
  if (userRole === AppEnum.UserRoleIds.ReportUser) {
    return "user-avatar-green user-avatar";
  } else if (userRole === AppEnum.UserRoleIds.CompanyAdmin) {
    return "user-avatar-primary user-avatar";
  } else if (userRole === AppEnum.UserRoleIds.SuperAdmin) {
    return "user-avatar-super user-avatar";
  } else {
    return "user-avatar-blue user-avatar";
  }
};

const UsersList = (props) => {
  const remainingFeatureBalance = useSelector((state) => state.remainingFeatureBalance);
  const { state, invitedUserState } = props;

  const sortedDates = state.users
    ?.map((user) => user.createdDateTime)
    .sort((a, b) => new Date(a) - new Date(b));

  return (
    <Fragment>
      <div className="users-container">
        {invitedUserState.users?.length > 0 ? (
          <div className="add-btn">
            <div className="total-invited-users">
              <span>
                Total <span>{invitedUserState.users?.length}</span>
              </span>
              <label> Invited Users</label>
            </div>

            {invitedUserState.users?.length >= 0 ? (
              remainingFeatureBalance === null ? 
              ("") :
              (remainingFeatureBalance <= 0 ? (
                <UpgradeButtonView balanceFeature = "USERS"/>
              ) : (
                <div className="add-new-btn-data">

                  <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                    onClick={() => props.handleAddNewUser()} 
                    rounded={true}
                    primary={true}
                  >
                    <Link className="add-new-link">
                      <i className="plus-svg">+</i> ADD NEW
                    </Link>
                  </ASButton>

                </div>
              ))
              ) : null}
          </div>
        ) : null}
        {invitedUserState.users?.length > 0 ? (
          <Paper className="user-list-paper">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableBody>
                  {invitedUserState.users?.map((user, index) => {
                    return (
                      <TableRow hover key={index} className="user-table-row">
                        <TableCell
                          align="left"
                          padding="checkbox"
                          className="avatar-cell"
                        >
                          <Avatar
                            sx={{
                              display: "inline-block",
                              lineHeight: "2",
                              textAlign: "center",
                              bgcolor:
                                user.userRole === AppEnum.UserRoleIds.ReportUser
                                  ? styles.avatarBackGreen
                                  : user.userRole ===
                                    AppEnum.UserRoleIds.CompanyAdmin
                                    ? styles.primaryColor
                                    : user.userRole ===
                                      AppEnum.UserRoleIds.SuperAdmin
                                      ? styles.avatarBackground
                                      : styles.avatarBackBlue,
                              color: styles.avatarBorderBlue,
                            }}
                            className={
                              user.userRole === AppEnum.UserRoleIds.ReportUser
                                ? "user-avatar-green user-avatar"
                                : user.userRole ===
                                  AppEnum.UserRoleIds.CompanyAdmin
                                  ? "user-avatar-primary user-avatar"
                                  : user.userRole ===
                                    AppEnum.UserRoleIds.SuperAdmin
                                    ? "user-avatar-super user-avatar"
                                    : "user-avatar-blue user-avatar"
                            }
                          >
                            <span>
                              {user?.name
                                ?.split(" ")
                                .map((digit) => digit[0])
                                .join("")
                                .substring(0, 2)
                                .toUpperCase()}
                            </span>
                          </Avatar>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.secondaryColor }}
                        >
                          {user?.name}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.email}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          {user?.userRoleName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: styles.mutedColor }}
                        >
                          <MoreVertIcon
                            id={`${String(index)}+user-vertIcon`}
                            aria-controls={
                              invitedUserState.isInvitedUser
                                ? `${String(index)}+menu`
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              invitedUserState.isInvitedUser
                                ? "true"
                                : undefined
                            }
                            onClick={(event) =>
                              props.openUserMenuOption(event, true, index)
                            }
                            className="edit-user-icon"
                          >
                            Dashboard
                          </MoreVertIcon>

                          <Menu
                            id={`${String(index)}+menu`}
                            aria-labelledby={index}
                            anchorEl={
                              invitedUserState.selectedRowIndex === index
                                ? invitedUserState.anchorEl
                                : null
                            }
                            open={
                              invitedUserState.selectedRowIndex === index
                                ? invitedUserState.isInvitedUser
                                : false
                            }
                            onClose={(event) =>
                              props.closeUserMenuOption(event)
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            className="edit-user-menu"
                          >
                            <MenuItem onClick={() => props.resendInvite(index)}>
                              Resend Invite
                            </MenuItem>
                            <MenuItem
                              onClick={(event) =>
                                props.openConfirmationModal(event, index)
                              }
                            >
                              Cancel Invite
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : null}
        <MuiDialog
          isShowPopUp={props.confirmationModalOpen}
          secondaryButtonAction={props.closeConfirmationModal}
          primaryButtonAction={props.cancelInvite}
          closeDialog={props.closeConfirmationModal}
          secondaryButtonTitle="No"
          primaryButtonTitle="Yes"
          titleDescription="Are you sure you want to cancel this invite?"
        />        
        <div className="add-btn">
          <div className="total-users">
            <span>
              Total <span>{state?.users?.length}</span>
            </span>
            <label>Users</label>
          </div>
          {invitedUserState.users?.length <= 0 ? (

            remainingFeatureBalance === null ? 
            ("") :
            (remainingFeatureBalance <= 0 ? (
              <UpgradeButtonView balanceFeature = "USERS"/>
            ) : (
              <div className="add-new-btn-data">

                <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                  onClick={() => props.handleAddNewUser()} 
                  rounded={true}
                  primary={true}
                >
                  <Link className="add-new-link">
                    <i className="plus-svg">+</i> ADD NEW
                  </Link>
                </ASButton>

              </div>
            ))
          ) : null}
        </div>

        <Paper className="user-list-paper">
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableBody>
                {state.users?.map((user, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        if (!(sortedDates && sortedDates.length > 0 && sortedDates[0] === user.createdDateTime)) {
                          props.editUserPermission(user);
                        }
                      }}
                      key={index}
                      className="user-table-row"
                    >
                      <TableCell
                        align="left"
                        padding="checkbox"
                        className="avatar-cell"
                      >
                        <Avatar
                          sx={{
                            display: "inline-block",
                            lineHeight: "2",
                            textAlign: "center",
                            bgcolor: getAvatarStyle(user),
                            color: styles.avatarBorderBlue,
                          }}
                          className={getAvatarClassName(user)}
                        >
                          <span>
                            {user?.userName
                              ?.split(" ")
                              .map((digit) => digit[0])
                              .join("")
                              .substring(0, 2)
                              .toUpperCase()}
                          </span>
                        </Avatar>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ color: styles.secondaryColor }}
                      >
                        {user?.userName}
                        {sortedDates &&
                          sortedDates.length > 0 &&
                          sortedDates[0] === user.createdDateTime ? (
                          <span className="owner-tag">{"Owner"}</span>
                        ) : null}
                      </TableCell>
                      <TableCell align="left" sx={{ color: styles.mutedColor }}>
                        {user?.userEmail}
                      </TableCell>
                      <TableCell align="left" sx={{ color: styles.mutedColor }}>
                      {sortedDates &&
                          sortedDates.length > 0 &&
                          sortedDates[0] === user.createdDateTime ? (
                          "Everything"
                        ) : renderAccessText(user)}
                        
                      </TableCell>

                      <TableCell align="left" sx={{ color: styles.mutedColor }}>
                        {user?.userRoleName}
                      </TableCell>
                      {sortedDates &&
                        sortedDates.length > 0 &&
                        sortedDates[0] === user.createdDateTime ? <TableCell /> : (
                        <TableCell align="left" sx={{ color: styles.mutedColor }}>
                          <MoreVertIcon
                            id={`${String(index)}+user-vertIcon`}
                            aria-controls={
                              state.isUserMenuOpen
                                ? `${String(index)}+menu`
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              state.isUserMenuOpen ? "true" : undefined
                            }
                            onClick={(event) =>
                              props.openUserMenuOption(event, false, index)
                            }
                            className="edit-user-icon"
                          >
                            Dashboard
                          </MoreVertIcon>

                          <Menu
                            id={`${String(index)}+menu`}
                            aria-labelledby={index}
                            anchorEl={
                              state?.selectedRowIndex === index
                                ? state.anchorEl
                                : null
                            }
                            open={
                              state?.selectedRowIndex === index
                                ? state.isUserMenuOpen
                                : false
                            }
                            onClose={(event) => props.closeUserMenuOption(event)}
                            anchorOrigin={{ vertical: "top", horizontal: "left" }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            className="edit-user-menu"
                          >

                            {props.loggedUserRole != AppEnum.UserRoleIds.ReportAdmin && props.loggedUserRole != AppEnum.UserRoleIds.ReportUser ?
                              <MenuItem
                                onClick={() => props.editUserPermission(user)}
                              >
                                Edit Permission
                              </MenuItem> : null
                            }

                            {sortedDates &&
                              sortedDates.length > 0 &&
                              sortedDates[0] === user.createdDateTime ? null : (
                              <>
                                {props.loggedUserEmail === user?.userEmail ? (
                                  <MenuItem
                                    onClick={(event) =>
                                      props.deleteSelectedUser(event, user, "organization")
                                    }
                                  >
                                    Leave the Organization
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={(event) =>
                                      props.deleteSelectedUser(event, user, "delete")
                                    }
                                  >
                                    Delete User
                                  </MenuItem>
                                )}
                              </>
                            )}
                          </Menu>

                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <MuiDialog
          isShowPopUp={props.deleteConfirmationModalOpen}
          secondaryButtonAction={props.closeDeleteConfirmationModal}
          primaryButtonAction={props.handleDeleteConfirmed}
          closeDialog={props.closeDeleteConfirmationModal}
          secondaryButtonTitle="No"
          primaryButtonTitle="Yes"
          titleDescription={props?.leaveOrganization ? "Are you sure you want to leave this organization?" : "Are you sure you want to delete this user?"}
        />         
      </div>
    </Fragment>
  );
};

export default UsersList;
