
import { MenuItem, Paper, Typography } from "@mui/material";
import themeColor from "../../../styles/_exports.module.scss";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import './data-transformation-script.scss';
import ExpandableView from "../../common/expandableView/expandableView";
import { useSelector } from "react-redux";


const DataTransformationScript = (props) => {

    const { state, handleEditor } = props;
    const isExpandedView = useSelector((state) => state.isExpandedView);

    return (
      <Paper
        sx={{
          minHeight: "260px",
          width: "1250px",
          padding: "20px",
          paddingBottom:"0px"
        }}
        ref={props.prevStepRef}
      >
        <>
        <ExpandableView>
            <Typography
                sx={{
                    color: themeColor.text_2,
                }}
                variant="body1"
                display="block"
            >
                Data Transformation Script
            </Typography>

            <div className="editor-container">
                <AceEditor
                    placeholder="Placeholder Text"
                    height={ isExpandedView ? "calc(-140px + 100vh)" : "calc(100vh - 283px)"}
                    width="100%"
                    mode="javascript"
                    name="blah2"
                    onLoad={() => {}}
                    onChange={(e) => handleEditor(e)}
                    fontSize={16}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={state.restDataTransformationScript}
                    setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    }}/>
            </div>
            </ExpandableView>
        </>
      </Paper>
    );
}

export default DataTransformationScript;
