import React, { useEffect, useRef, useState } from "react";
import { ASStepperView } from "./as-stepper-view";
import "./as-stepper.scss";
import { CollectionStepLabelConstant, ConnectionStepLabelConstant } from "../../constants/as-stepper-step-label";

export function ASStepper({
    steps, scrollToPreviousStep,
    save, setErrorMessage,
    activeStep=0 , uId,
    setActiveStep, isTestingConnection,
    deleteConnection,sysCollectionTypeId,isConnection = false,backTwoStep, updateActiveStatus, activeStatus, isTemplateCollection, isSavingCollection, stepperViewFromConnection,isShowHeader,isCloudSteps,
    setSaveFormData,previousSelectedStepIds,setPreviouSelectedStepIds, collectionUId, isCollectionSteps, showSaveCollection, handleSaveChangesWithPopupedButton, isTestedAfterSaveAppears, setShowTestCollectionPopup, isRestSteps
}) {

    const [isShowSteps, setIsShowSteps] = useState(false);
    
    const stepRefs = useRef([]);

    useEffect(() => {
        if (steps?.length > 0) {
            stepRefs.current = steps.map((_, i) => stepRefs.current[i] ?? React.createRef());
        }
    }, [steps]);

    useEffect(() => {
        if (isCloudSteps && uId){
            setActiveStep(steps?.length - 1);
        }
    }, [isCloudSteps]);

    useEffect(()=>{
        if(uId && !stepperViewFromConnection && !isCloudSteps){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    },[]);

    const handleNext = () => {

        if (activeStep === 0) {
            setIsShowSteps(true);
          }

        if(activeStep === 0 && stepperViewFromConnection && previousSelectedStepIds.length > 1){
            let size = previousSelectedStepIds.length;
            if(previousSelectedStepIds[size-1] != previousSelectedStepIds[size-2]){
                setSaveFormData(null);
            }
        }

        setActiveStep((prevActiveStep) => {
            return prevActiveStep + 1
        });
        setErrorMessage(null);
        // setTimeout(()=>{
        //     scrollToActiveStep(1)
        // },600)
    }; 

    const handleStepClick = (label) => {
        const selectedStepIndex = steps.findIndex(step => step.label === label);
        if(uId){
            if(((isRestSteps && selectedStepIndex == 9) || (!isRestSteps && selectedStepIndex ==7)) && !isTestedAfterSaveAppears && showSaveCollection){
                setShowTestCollectionPopup(true);
            }
            else{
                setActiveStep(selectedStepIndex);
            }
        }
        else{
            if (activeStep > selectedStepIndex) {
                setActiveStep(selectedStepIndex);
            }
        }
    }

    const handleBack = () => {
        //setActiveStep((prevActiveStep) => backTwoStep ? prevActiveStep - 2 : prevActiveStep - 1);
        if (backTwoStep ? activeStep === 2 : activeStep === 1) {
            setIsShowSteps(false);
          }

        if(activeStep == 1 && stepperViewFromConnection){
            let size = previousSelectedStepIds.length;

            setPreviouSelectedStepIds((previousSelectedStepIds) =>{
                return [...previousSelectedStepIds,previousSelectedStepIds[size-1]];
            });
        }

        setActiveStep((prevActiveStep) => {
            const newActiveStep = backTwoStep ? prevActiveStep - 2 : prevActiveStep - 1;
            return newActiveStep < 0 ? 0 : newActiveStep;
        });
        // scrollToActiveStep(-1)
        setErrorMessage(null);
        // if (scrollToPreviousStep)
        //     scrollToPreviousStep()
    };

    // completes the final step
    const handleSave = () => {
        save();
        setErrorMessage(null);
    };

    const getButtonWidth = (activeStep) => {
        if (activeStep?.label == CollectionStepLabelConstant.Select_Connection 
            || activeStep?.label == ConnectionStepLabelConstant.Select_The_Type_Of_Connection){
            return "50%"
        }
        else if (activeStep?.label == CollectionStepLabelConstant.Collection_Type_And_Object 
            || activeStep?.label == CollectionStepLabelConstant.Basic_Information
            || activeStep?.label ==  ConnectionStepLabelConstant.Test_Connection){
            return "820px"
        }
        else if (activeStep?.label == CollectionStepLabelConstant.Parameters 
            || activeStep?.label == CollectionStepLabelConstant.Select_Columns_And_Formatting
            || activeStep?.label == CollectionStepLabelConstant.Collection_Column_Filters){
            return "1280px"
        }
        else if (activeStep?.label == CollectionStepLabelConstant.Test_Data 
            || activeStep?.label == CollectionStepLabelConstant.Data_Transformation_Script 
            || activeStep?.label == CollectionStepLabelConstant.Data_After_Transformation 
            || activeStep?.label == CollectionStepLabelConstant.Send_Body_As 
            || activeStep?.label == CollectionStepLabelConstant.Select_An_Object){
            return "1320px"
        }
        else if (activeStep?.label ==  ConnectionStepLabelConstant.Save_Connection_Details 
            || activeStep?.label ==  ConnectionStepLabelConstant.Save_Connection ){
            return "828px"
        }
        else if (activeStep?.label ==  ConnectionStepLabelConstant.Connection_Detail) {
            return "970px"
        }
    };
    const customScrollTo = (targetY, duration) => {
        const startY = window.scrollY;
        const distance = targetY - startY;
        let startTime = null;
    
        const step = (currentTime) => {
          if (!startTime) startTime = currentTime;
          const progress = currentTime - startTime;
          const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
          const easedProgress = easeInOutQuad(Math.min(progress / duration, 1));
          window.scrollTo(0, startY + distance * easedProgress);
          if (progress < duration) {
            requestAnimationFrame(step);
          }
        };
    
        requestAnimationFrame(step);
      };
    
      const scrollToActiveStep = () => {
        const stepRef = stepRefs?.current[activeStep]?.current;
        if (stepRef) {
          const offset = 70; // Adjust the offset as needed
          const stepTop = stepRef.getBoundingClientRect().top + window.scrollY;
          customScrollTo(stepTop - offset, 500); // Adjust duration as needed
        }
      };

      const isStepVisible = (index) => {
        if (isCollectionSteps){
            if (collectionUId) return true;
    
            return isShowSteps || index === 0;
        }
        
        return true;
      };
    

    return (
        <ASStepperView
            showSendAsStep={sysCollectionTypeId == 7}
            steps={steps}
            deleteConnection={deleteConnection}
            activeStep={activeStep}
            handleBack={handleBack}
            handleSave={handleSave}
            handleNext={handleNext}
            uId={uId}
            isTestingConnection={isTestingConnection}
            setActiveStep={setActiveStep}
            isConnection={isConnection}
            updateActiveStatus={updateActiveStatus}
            activeStatus={activeStatus}
            isTemplateCollection={isTemplateCollection}
            isSavingCollection={isSavingCollection}
            stepperViewFromConnection={stepperViewFromConnection}
            isShowHeader={isShowHeader}
			handleStepClick={handleStepClick}
            getButtonWidth={getButtonWidth}
            stepRefs={stepRefs}
            scrollToActiveStep={scrollToActiveStep}
            isStepVisible={isStepVisible}
            isCloudSteps={isCloudSteps}
            showSaveCollection={showSaveCollection}
            handleSaveChangesWithPopupedButton={handleSaveChangesWithPopupedButton}
        />
    );
}
