
import { MenuItem, Paper, Typography } from "@mui/material";
import { ASRadioGroup } from "../../../shared/as-radio-group";
import { RestServiceConstants } from "../../../constants/rest-service-constant";
import themeColor from "../../../styles/_exports.module.scss";
import { ASInput } from "../../../shared/as-inputs/as-input";
import { ASTextField } from "../../../shared/as-text-fields";
import ExpandableView from "../../common/expandableView/expandableView";
import { useSelector } from "react-redux";

const SendBodyAs = (props) => {
    const isExpandedView = useSelector((state) => state.isExpandedView);

    return (
      <Paper
        sx={{
          minHeight: "100px",
          width: "1250px",
          padding: "20px",
          paddingBottom:"0px"
        }}
        ref={props.prevStepRef}
      >
        <>
        <ExpandableView>
            <Typography
                sx={{
                    color: themeColor.text_2,
                }}
                variant="body1"
                display="block"
            >
                Send Body As
            </Typography>

            <ASRadioGroup
                radioInputs={props.radioButtons}
                onChange={(_e) => props.onRadioInputChange(_e.target.value)}
            />

            {props.radioButtons.defaultValue === RestServiceConstants.HTTPMETHODS.SEND_BODY_AS_OPTION[0].value && (
                <>
                    <Typography
                        sx={{
                            marginTop: "10px",
                            color: themeColor.text_2,
                        }}
                        variant="body2"
                        display="block"
                    >
                        JSON/XML Body
                    </Typography>

                    <ASTextField
                        sx={{
                            marginTop: "10px",
                            width: "100%",
                            height: isExpandedView ? "calc(100vh - 201px);" : "100%",
                            "& .MuiInputBase-root": {
                              height: "100%",
                            },
                            "& .MuiInputBase-inputMultiline": {
                              height: "100% !important",
                              overflow: "auto",
                            },
                        }}
                        multiline={true}
                        rows={10}
                        value={props.state.restBody}
                        onChange={(event) =>
                            props.onChangeRestBodyHandler(event.target.value)
                        }
                    />
                </>
            )}
         </ExpandableView>
        </>
      </Paper>
    );
}

export default SendBodyAs;
