import * as collectionService from '../../../services/data-collection.service';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Parameters } from "../../../components/asheetPlugin/search-parameters/parameters";
import { testData } from '../../../services/test-data.service';
import { AppEnum } from '../../../constants/app-enum';

export const SearchParameterController = ({ collection, index, dataCollection, setDatacollection, setSearchOptionsParameters, searchOptionsParameters, isPopup }) => {

    const complexTypeValue = JSON.parse(collection?.complexTypeValue);
    const [dynamicList, setDynamicList] = useState();
    const dateString = collection?.isSystemDefineVariable ? collection?.parameterValue : collection?.defaultValue;
    const dateObject = new Date(dateString);


    useEffect(() => {
        if (collection?.sysDataTypeId == AppEnum.DataTypeId.DynamicList){
            getCollectionDetail(complexTypeValue);
        }
    }, [collection])

    
    const getCollectionDetail = async (parsedComplexTypeValue) => {
        try {
            const response = await collectionService.getCollectionDetails(parsedComplexTypeValue?.DataCollectionUId);
            if (response?.hasError) {
                toast.error(response?.errorMessage);
            }
            else if (response?.data) {
                let testDataCollectionModal = {
                    connectionUId: response?.data?.connectionUId,
                    sysCollectionTypeId: response?.data?.sysCollectionTypeId,
                    sourceName: response?.data?.sourceName,
                    restRequestMethod: response?.data?.restRequestMethod,
                    restRequestIsAsync: response?.data?.restRequestIsAsync,
                    restBody: response?.data?.restBody,
                    restSendAsCD: response?.data?.restSendAsCD,
                    collectionParameters: response?.data?.collectionParameters,
                    collectionColumns: response?.data?.collectionColumns,
                    uId: response?.data?.uId,
                    returnFlatternData: response?.data?.sysCollectionTypeId === 7 ? true : false,
                    sourceNamePart4: response?.data?.sourceNamePart4,
                    IsASTenantUIdApplicable: false,
                    restDataTransformationScript : response?.data?.restDataTransformationScript
                }
                return getDynamicList(testDataCollectionModal, parsedComplexTypeValue);
            }
        } catch (error) {

        }
    }

    const getDynamicList = async (model, parsedComplexTypeValue) => {
        try {
            testData(model,null,getDynamicListOptions,parsedComplexTypeValue);
        } catch (error) {
            toast.error(error);
        }
    }

    const getDynamicListOptions = (data, parsedComplexTypeValue) => {
        const displayColumn = parsedComplexTypeValue?.DisplayColumn;
        const valueColumn = parsedComplexTypeValue?.ValueColumn;
        let options = [];

        if (!displayColumn || !valueColumn) {
            return;
        }

        if (data?.length === undefined) {
            var displayColumnList = data[displayColumn]?.filter(value => value !== null)
            var valueColumnList = data[valueColumn]?.filter(value => value !== null)

            options = displayColumnList.map((label, index) => ({
                label,
                value: valueColumnList[index]
            }));
        }
        else if (data?.length > 0) {
            options = data?.map(item => ({
                label: item[displayColumn],
                value: item[valueColumn]
            }));
        }

        setDynamicList(options);
    };
    const handleOnInputChange = (e, index, isComplexTypeValue, isDynamicList) => {

        let value = e?.target?.value;

        let prevParams = isPopup ? [...searchOptionsParameters] : [...dataCollection?.collectionParameters];

        if (index >= 0 && index < prevParams?.length) {

            if (isComplexTypeValue) {
                let complexTypeValue = JSON.parse(prevParams[index]?.complexTypeValue);
                let complexParamValue = null;

                if (!isDynamicList){
                    complexParamValue = complexTypeValue?.find(i => i.Label == value)?.Value;
                }

                prevParams[index].parameterValue = isDynamicList ? value : complexParamValue;
            }
            else {
                prevParams[index].parameterValue = value;
            }
        }

        if (isPopup) {
            setSearchOptionsParameters(prevParams);
        }
        else {
            setDatacollection((prevState) => { return { ...prevState, collectionParameters: prevParams } });
        }
    }


    return (
        <Parameters
            collection={collection}
            index={index}
            dateObject={dateObject}
            complexTypeValue={complexTypeValue}
            dynamicList={dynamicList}
            handleOnInputChange={handleOnInputChange} />
    )
}